import {store} from "@/store";
import axios from "axios";

// Увеличивает рейтинг пользователя на 1 после завершения тренировки
export const finishedWorkout = (workoutUuid) => {
    const endpoint = "/api/rating/finished-workout";
    let params = {
        'user_vk_id': parseInt(store.state.launchParams["vk_user_id"]),
        'url': store.state.launchURL,
        'workout_uuid': workoutUuid,
    };

    return axios.post(endpoint, params);
}

// Возвращает список участников, отсортированный по количеству завершённых тренировок
export const getRatingTop = (limit) => {
    const endpoint = "/api/rating/get-rating";
    let params = {
        'user_vk_id': parseInt(store.state.launchParams["vk_user_id"]),
        'url': store.state.launchURL,
        'limit': limit,
    };

    return axios.post(endpoint, params);
}

// Возвращает данные по конкретному участнику
export const getStatistic = () => {
    const endpoint = "/api/rating/get-statistic";
    let params = {
        'user_vk_id': parseInt(store.state.launchParams["vk_user_id"]),
        'url': store.state.launchURL,
    };

    return axios.post(endpoint, params);
}