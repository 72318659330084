<template>
  <div
      :class="{ 'justify-content-center': isIPhone() }"
      @click="clickByPanelHeader"
      class="head fixed flex w-full top-0 items-center"
      v-if="isMobile"
  >
    <div class="arrow">
      <slot name="arrow"></slot>
    </div>

    <div :class="{ 'margin-title': hasArrowSlot() }">
      <slot name="text"></slot>
    </div>
  </div>
</template>

<script>
import {isIPhone, isMobile} from "../../lib/utils";
import bridge from "@vkontakte/vk-bridge";
import {store} from "../../store";

export default {
  name: "PanelHeader",
  methods: {
    clickByPanelHeader() {
      window.scrollTo(0, 0);
      bridge.send("VKWebAppScroll", {top: 0, speed: 0}).catch(err => {
        console.log("VKWebAppScroll", err);
      });
    },

    isIPhone() {
      return isIPhone(store);
    },

    hasArrowSlot() {
      return !!this.$slots.arrow
    },
  },
  computed: {
    isMobile() {
      return isMobile();
    },
  }
};
</script>

<style lang="scss" scoped>
.head {
  z-index: 9999;
  /* Высота статус-бара в iOS 10 */
  padding-top: 10px;
  height: 55px;

  /* Высота статус-бара в iOS 11.0 */
  padding-top: calc(10px + constant(safe-area-inset-top));
  height: calc(55px + constant(safe-area-inset-top));

  /* Высота статус-бара в iOS 11+ */
  padding-top: calc(10px + env(safe-area-inset-top));
  height: calc(55px + env(safe-area-inset-top));

  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 10px;

  background-color: var(--panel-header-background);
  color: var(--panel-header-color);

  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
  0 2px 4px -1px rgba(0, 0, 0, 0.06);

  font-weight: 600;
  font-size: 16px;

  & img {
    height: 25px;
    margin-right: 7px;
  }
}

.arrow {
  position: absolute;
  left: 10px;
}

.margin-title {
  margin-left: 30px;
}
</style>
