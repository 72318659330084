import {store} from "@/store";
import axios from "axios";

// Загружает список всех упражнений
export const getAllExercises = () => {
    const endpoint = "/api/exercise/get-all";
    let params = {
        'user_vk_id': parseInt(store.state.launchParams["vk_user_id"]),
        'url': store.state.launchURL,
    };

    return axios.post(endpoint, params);
}

// Загружает упражнение по его Id
export const getExerciseById = (id) => {
    const endpoint = "/api/exercise/get-by-id";
    let params = {
        'user_vk_id': parseInt(store.state.launchParams["vk_user_id"]),
        'url': store.state.launchURL,
        'exercise_id': id,
    };

    return axios.post(endpoint, params);
}

// Возвращает список упражнений по id тренировки
export const getExerciseByWorkoutId = (workoutId) => {
    const endpoint = "/api/exercise/get-by-workout-id";
    let params = {
        'user_vk_id': parseInt(store.state.launchParams["vk_user_id"]),
        'url': store.state.launchURL,
        'workout_id': workoutId,
    };

    return axios.post(endpoint, params);
}