<template>
  <div>
    <p class="text-xs">
      <span class="font-medium">Внимание!</span> Перед началом тренировок необходимо
      проконсультироваться с врачом. Если после или во время упражнений боль усиливается, необходимо прекратить
      тренировки и обратиться за медицинской помощью. Не занимайтесь самолечением!
    </p>

    <div class="link cursor-pointer text-center text-xs mt-3" @click="clickCopyrightHandler">
      <span class="copyright-link">Источники</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Copyright",
  methods: {
    clickCopyrightHandler() {
      this.$router.push({path: `/copyright`});
    },
  },
}
</script>

<style lang="scss" scoped>
  p, span {
    color: var(--white-wrapper-color);
  }

  .copyright-link {
    text-decoration: underline;
    color: var(--vk_text_blue);
  }
</style>