<template>
  <div id="advertising" v-if="showAdvertising">
    <div class="white-wrapper advertising">
      <spinner/>
      <span class="advertising__tnx">Спасибо, что пользуетесь нашим приложением</span>
      <span class="advertising__tnx-heart">❤️</span>
    </div>
  </div>
</template>

<script>
import bridge from "@vkontakte/vk-bridge";
import {store} from "../../store";
import config from "../../config";

import Spinner from "../Spinner/Spinner";

export default {
  name: "Advertising",
  components: {Spinner},
  data() {
    return {
      showAdvertising: false,
    }
  },
  mounted() {
    if (config["dev_mode"] === true) {
      this.endAdvertisingSignal();
      return;
    }

    // Пользователь зашёл с десктопа, показывать рекламу ненужно
    if (store.state.launchParams["vk_platform"] === "desktop_web") {
      this.endAdvertisingSignal();
      return;
    }

    // Чтобы не показывать рекламу несколько раз одному пользователю
    if (sessionStorage.getItem("showedAdvertising") !== "1") {
      this.showAdvertising = true;
      setTimeout(() => {
        bridge.send("VKWebAppShowNativeAds", {ad_format: "preloader"}).finally(() => {
          this.endAdvertisingSignal();
        });
      }, 1000)
    } else {
      this.endAdvertisingSignal();
    }
  },

  methods: {
    endAdvertisingSignal() {
      this.showAdvertising = false;
      this.$emit("endShowingAdvertising");
      sessionStorage.setItem("showedAdvertising", "1");
    }
  }
}
</script>

<style scoped>
.advertising {
  width: 200px;
  height: 200px;

  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.advertising__tnx {
  text-align: center;
  margin-top: 7px;
  font-size: 12px;
  font-weight: 500;
  color: var(--default-text-color);
}

.advertising__tnx-heart {
  color: red;
  font-size: 18px;
}
</style>
