<template>

  <div class="wrapper">
    <div v-if="!isLoading">
      <ul>
        <li class="item-rating" v-for="user in users" :key="user.id"
            :class="{ 'current-user': isCurrentUser(user.user_id) }"
            @click="showUser(user.user_id)"
        >

          <img :src="user.user_avatar ? user.user_avatar : '/img/avatar/no_avatar.png'"
               class="rounded-full mr-5 h-16 w-16 bg-gray-200 border-none"/>
          <div class="overflow-hidden">
            <p class="text-sm font-medium">{{ user.first_name }} {{ user.last_name }}</p>
            <p class="text-xs">{{ user.finished_workouts }} {{ getExerciseEnding(user.finished_workouts) }}</p>
            <p class="text-xs mt-1">Начало занятий: {{ getHumanDate(user.created_at) }}</p>
          </div>

        </li>
      </ul>
    </div>

    <div class="text-center" v-if="!isLoading && users.length === 0">
      <p class="m-0 text-sm">Рейтинг пуст.</p>
    </div>

    <spinner v-if="isLoading"/>
  </div>
</template>

<script>
import {store} from "@/store";
import {decrementHistoryDepth, getNumEnding, heightIFrame, setPanelHeader} from "@/lib/utils";
import {getRatingTop} from "@/services/rating";
import Spinner from "@/components/Spinner/Spinner";

export default {
  name: "ExerciseList",
  components: {Spinner},
  data() {
    return {
      userId: 0,
      users: [],

      isLoading: false,
    }
  },
  mounted() {
    decrementHistoryDepth();
    setPanelHeader("Рейтинг", true);
    heightIFrame();

    this.getCurrentUser();
    this.loadRating();
  },
  methods: {
    getCurrentUser() {
      this.user = store.state.launchParams["vk_user_id"];
    },

    loadRating() {
      this.isLoading = true;

      getRatingTop(50).then(res => {
        if (res.data.response.items !== null) {
          this.users = res.data.response.items;
        }
      }).catch(err => {
        console.log(err);
      }).finally(() => {
        this.isLoading = false;
      });
    },

    getExerciseEnding(number) {
      return getNumEnding(number, ['тренировка', 'тренировки', 'тренировок']);
    },

    getHumanDate(unixTimestamp) {
      const milliseconds = unixTimestamp * 1000;
      const dateObject = new Date(milliseconds);
      const options = {year: 'numeric', month: 'long', day: 'numeric'};

      return dateObject.toLocaleString('ru-RU', options);
    },

    isCurrentUser(userId) {
      return parseInt(userId) === parseInt(store.state.launchParams["vk_user_id"]);
    },

    showUser(userId) {
      const url = "https://vk.com/id" + userId;
      window.open(url, '_blank');
    },
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  @apply p-4 mt-3 rounded-md;
  background-color: var(--white-wrapper-background);
  color: var(--white-wrapper-color);
}

.current-user {
  background-color: var(--rating-current-user);
  color: var(--rating-current-user-color);
}

.item-rating {
  @apply rounded-md py-2 px-4 list-none flex items-center;
}

.item-rating:not(:first-child) {
  @apply mt-2;
}
</style>