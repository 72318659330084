<template>
    <div class="button">
    <div v-if="! isSubscribed && ! isLoading" @click="subscribe" class="flex items-center">
      <div class="w-10 flex justify-center mr-3"><NotificationOutline color="#5c9ce6" /></div>
      <div class="w-full flex text-xs font-medium">Включить напоминание о тренировках</div>
    </div>

    <div v-if="isSubscribed && ! isLoading" @click="unsubscribe" class="flex items-center">
      <div class="w-10 flex justify-center mr-3"><NotificationDisableOutline color="#5c9ce6" /></div>
      <div class="w-full flex text-xs font-medium">Отключить напоминание о тренировках</div>
    </div>

    <div class="flex justify-center w-full" v-if="isLoading">
      <Spinner />
    </div>
  </div>
</template>

<script>
import bridge from "@vkontakte/vk-bridge";
import config from "@/config";
import {checkSubscription, subscribeNotification, unsubscribeNotification} from "@/services/notification";

import NotificationOutline from "../Svg/28/NotificationOutline";
import NotificationDisableOutline from "../Svg/28/NotificationDisableOutline";
import Spinner from "@/components/Spinner/Spinner";

export default {
  name: "Notification",
  components: {Spinner, NotificationDisableOutline, NotificationOutline},
  data() {
    return {
      isSubscribed: false,
      isLoading: false,
      errorSubscribe: false,
    }
  },
  mounted() {
    this.check();
  },
  methods: {
    subscribe() {
      this.isLoading = true;
      bridge.send("VKWebAppAllowMessagesFromGroup", {
        "group_id": config["group_id"],
      }).then(() => {
        subscribeNotification().then(() => {
          this.isLoading = false;
          this.isSubscribed = true;
        }).catch(() => {
          this.isSubscribed = false;
          this.errorSubscribe = true;
          this.isLoading = false;
        });
      }).catch(err => {
        console.log("VKWebAppAllowMessagesFromGroup", err);
        this.isSubscribed = false;
        this.isLoading = false;
      });
    },

    unsubscribe() {
      this.isLoading = true;

      unsubscribeNotification().then(() => {
        this.isSubscribed = false;
        this.isLoading = false;
      }).catch(() => {
        this.isSubscribed = true;
        this.errorSubscribe = true;
        this.isLoading = false;
      });
    },

    check() {
      checkSubscription().then((result) => {
        this.isSubscribed = result['data']['response'];
        this.isLoading = false;
      }).catch(() => {
        this.isSubscribed = false;
        this.errorSubscribe = true;
        this.isLoading = false;
      });
    },
  }
}
</script>

<style lang="scss" scoped>
  .button {
    @apply px-6 py-2 rounded-md cursor-pointer text-sm leading-5 w-full
    outline-none h-16 flex items-center;

    background-color: var(--white-wrapper-background);
    color: var(--white-wrapper-color);
  }
</style>