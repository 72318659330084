<template>
  <svg :style="{ color: color }" :class="[ classes ]" height="28px" version="1.1" viewBox="0 0 28 28" width="28px" xmlns="http://www.w3.org/2000/svg"
       xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
      <g id="notification_outline_28">
        <rect height="28" width="28" x="0" y="0"></rect>
        <path
            id="↳-Icon-Color"
            d="M14.0046028,3.5 C18.5358601,3.5 22,7.29406785 22,12.0814651 L21.9953979,13.9081084 C21.9958228,14.2153952 22.1945877,14.5706814 22.7335216,15.2049931 C22.8012144,15.2846658 22.8649419,15.3585239 23.0042724,15.5194663 C24.0744404,16.7576538 24.5,17.4744714 24.5,18.5882353 C24.5,19.0892946 24.4430332,19.4785282 24.2055926,19.9455699 C23.69956,20.9409276 22.6182188,21.5 21,21.5 L18.3988532,21.5006982 C17.7309515,23.7645264 16.2123212,24.9999999 14,24.9999999 C11.7876788,24.9999999 10.2690485,23.7645264 9.6011468,21.5006982 L7,21.5 C5.33483077,21.5 4.24202957,20.9258124 3.75797492,19.8962478 C3.54802005,19.4496823 3.5,19.0874607 3.5,18.5882353 C3.5,17.4749766 3.92475347,16.7581996 4.9928315,15.520155 C5.13190601,15.3592125 5.19551502,15.285356 5.26308201,15.205685 C5.80170622,14.5705706 6,14.215121 6,13.9071207 L6,12.0789474 C6,7.29571186 9.47202448,3.5 14.0046028,3.5 Z M16.2794024,21.5008013 L11.7205976,21.5008013 C12.1713889,22.5431725 12.8986588,23.0000001 14,23.0000001 C15.1013412,23.0000001 15.8286111,22.5431725 16.2794024,21.5008013 Z M14.0046028,5.5 C10.6183529,5.5 8,8.3624548 8,12.0789474 L8,13.9071207 C8,14.8348489 7.61560159,15.5238985 6.78840913,16.4992758 C6.71508593,16.5857342 6.64587176,16.6660989 6.5071685,16.8265942 C5.74712153,17.7075898 5.5,18.1246106 5.5,18.5882353 C5.5,18.8143528 5.51596919,18.9348106 5.56791451,19.0452961 C5.69184037,19.3088814 6.05557968,19.5 7,19.5 L21,19.5 C21.9142367,19.5 22.2862841,19.3076449 22.4227634,19.0391925 C22.4818467,18.9229767 22.5,18.7989414 22.5,18.5882353 C22.5,18.1251158 22.2524476,17.7081357 21.4911248,16.8272829 C21.3521661,16.6667875 21.2828259,16.5864244 21.2093691,16.4999676 C20.3805492,15.5244674 19.9953972,14.8353358 19.9953972,13.9071207 L20,12.0789474 C20,8.36007608 17.388633,5.5 14.0046028,5.5 Z"
            fill="currentColor" fill-rule="nonzero"></path>
      </g>
    </g>
  </svg>

</template>

<script>
export default {
  name: "NotificationOutline",
  props: {
    color: {
      type: String
    },
    classes: {
      type: String
    }
  }
}
</script>

<style scoped>

</style>