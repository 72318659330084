<template>
  <div>
    <div class="w-full" v-if="! showingAdvertising">
      <div class="wrapper">
        <div v-if="! isLoading">
          <div class="flex justify-center mb-5 h-24" v-if="workoutInfo.slug">
            <img :src="'/img/workout/covers/' + workoutInfo.slug + '.png'" class="w-24 h-auto"/>
          </div>

          <!-- Название тренировки   -->
          <h3 class="text-center font-medium text-xl mb-3">{{ workoutInfo.title }}</h3>

          <!--  Описание тренировки  -->
          <p class="text-sm">{{ workoutInfo.long_description }}</p>

          <!--  Кнопка начала тренировки  -->
          <span class="button start-button" @click="startWorkout">Старт</span>

          <!--  Продолжительность  -->
          <div class="text-xs mt-3 text-center">
            <p class="workout-duration">
              <span class="block font-bold">Продолжительность</span>
              ~ {{ workoutInfo.duration }} {{ getMinuteEnding(workoutInfo.duration) }}
            </p>
          </div>
        </div>

        <spinner v-else/>
      </div>
    </div>

    <!-- Плашка с рекламой -->
    <advertising @endShowingAdvertising="showingAdvertising = false"/>
  </div>
</template>

<script>
import {store} from "@/store";
import {decrementHistoryDepth, getNumEnding, heightIFrame, setPanelHeader} from "@/lib/utils";
import {getExerciseByWorkoutId} from "@/services/exercise";
import {getWorkoutBySlug} from "@/services/workout";

import Spinner from "@/components/Spinner/Spinner";
import Advertising from "../../components/Advertising/Advertising";

export default {
  name: "Welcome",
  components: {Advertising, Spinner},
  data() {
    return {
      workoutInfo: {},

      isLoading: false,
      error: "",

      showingAdvertising: true,
    }
  },
  mounted() {
    decrementHistoryDepth();
    setPanelHeader("", true);
    heightIFrame();

    this.loadWorkoutInfo();

    // Очистка данных о текущей тренировке
    store.commit("workout/setCurrentProgressClear");
  },
  methods: {
    loadWorkoutInfo() {
      this.isLoading = true;

      // Загрузка данных о самой тренировке
      getWorkoutBySlug(this.$route.params.id).then(res => {
        this.workoutInfo = res.data.response.item;
        store.commit("workout/setWorkoutCurrentUUID", res.data.response.uuid);

        // Загрузка упражнений для полученной тренировке
        getExerciseByWorkoutId(this.workoutInfo.id).then(res => {
          this.exercises = res.data.response.items;

          // Сохранение упражнений в сторе
          store.commit("workout/setExercisesByWorkout", {
            type: this.$route.params.id,
            exercises: this.exercises,
          });
        }).catch(err => {
          throw "Ошибка получения упражнений: " + err;
        });
      }).catch(err => {
        console.log('Err: ', err);
      }).finally(() => {
        this.isLoading = false;
      });
    },

    startWorkout() {
      this.$router.replace({path: `/workout/exercise/${this.$route.params.id}`});
    },

    getMinuteEnding(number) {
      return getNumEnding(number, ['минута', 'минуты', 'минут']);
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  @apply p-4 mt-3 rounded-md;
  background-color: var(--white-wrapper-background);
  color: var(--white-wrapper-color);
}

.button {
  @apply block text-center cursor-pointer rounded-md px-6 py-2 text-gray-700 font-medium text-sm mt-3 shadow-md outline-none;
}

.start-button {
  color: white;
  background-color: var(--vk_btn_blue);
}

.workout-duration {
  color: var(--default-text-color);
}
</style>