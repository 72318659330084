<template>
  <div class="wrapper">
    <div class="info" v-if="!isLoading">
<!--      <h3 class="text-md font-medium mb-3">Мои результаты</h3>-->

      <div v-if="Object.keys(rating).length > 0">
        <p v-if="rating.finished_workouts > 0">
          {{ getEnding(rating.finished_workouts, ['Выполнена', 'Выполнено', 'Выполнено']) }} <strong>{{ rating.finished_workouts }}</strong> {{ getEnding(rating.finished_workouts, ['тренировка', 'тренировки', 'тренировок']) }} <img src="/img/emoji/finger_up.png" class="inline h-4 -mt-1"/></p>
        <p v-if="rating.rank > 0">Я занимаю <strong>{{ rating.rank }} место</strong> в рейтинге <img src="/img/emoji/cup.png" class="inline h-4"/></p>

        <p class="text-center mt-3" v-if="rating.rank > 50">
          Нужно поднажать, чтобы попасть в список самых усердных! Пройди тренировку прямо сейчас
          <img src="/img/emoji/index_finger_down.png" class="inline h-4"/>
        </p>
      </div>

      <div class="text-center" v-if="Object.keys(rating).length === 0">
        <p class="text-sm">
          Пока ни одной тренировки не завершено. Пройди тренировку прямо сейчас
          <img src="/img/emoji/index_finger_down.png" class="inline h-4"/>
        </p>
      </div>

    </div>

    <div class="flex justify-center w-full" v-if="isLoading">
      <Spinner/>
    </div>
  </div>
</template>

<script>
import Spinner from "@/components/Spinner/Spinner";
import {getStatistic} from "@/services/rating";
import {store} from "@/store";
import {getNumEnding} from "@/lib/utils";

export default {
  name: "Statistic",
  components: {Spinner},
  data() {
    return {
      rating: {},

      isLoading: false,
    }
  },
  mounted() {
    this.loadStatistic();
  },
  methods: {
    loadStatistic() {
      // Проверяем наличие в сторе
      const storedRating = store.state.rating.userRating;
      console.log(storedRating);
      if (Object.keys(storedRating).length > 0) {
        this.rating = storedRating;
        return;
      }

      this.isLoading = true;
      getStatistic().then(res => {
        if (res.data.response.item !== null) {
          this.rating = res.data.response.item;

          // Сохранение рейтинга пользователя в сторе
          store.commit("rating/setUserRating", this.rating);
        }
      }).catch(err => {
        console.log(err);
      }).finally(() => {
        this.isLoading = false;
      });
    },

    getEnding(number, values) {
      return getNumEnding(number, values);
    },
  }
}
</script>


<style lang="scss" scoped>
.wrapper {
  @apply mt-3 rounded-md p-4;
  background-color: var(--white-wrapper-background);
  color: var(--white-wrapper-color);
}

.info p {
  @apply text-sm;
}
</style>