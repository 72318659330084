<template>
  <div>
    <p>
      Упражнения разработаны при участии профессора Е. А. Таламбума, кафедра лечебной физкультуры и спортивной медицины Первого МУГУ им. И.М. Сеченова.
    </p>
  </div>
</template>

<script>
import {decrementHistoryDepth, heightIFrame, setPanelHeader} from "@/lib/utils";

export default {
  name: "Copyright",
  mounted() {
    decrementHistoryDepth();
    setPanelHeader("Источники", true);
    heightIFrame();
  },
  updated() {
    heightIFrame();
  },
};
</script>

<style scoped>
p {
  color: var(--white-wrapper-color);
}
</style>
