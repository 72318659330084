export default {
    namespaced: true,
    state: {
        exercises: [],
    },
    mutations: {
        setExercises(state, exercises) {
            state.exercises = exercises;
        },
    }
};
