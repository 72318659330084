<template>
  <div>
    <div v-if="! isLoading && totalExercises > 0">
      <div v-if="executedExercises > 0">
        <div class="wrapper">
          <h3 class="font-medium text-2xl my-3 text-center">Тренировка завершена</h3>

          <div class="h-32 my-5">
            <img src="/img/workout/finish/congratulation.png" class="rounded-md mx-auto h-32"/>
          </div>

          <div class="mb-3 text-center" v-if="totalExercises > 1">
            <p class="text-2xl font-medium mb-1">
              {{ executedExercises }} / {{ totalExercises }}
            </p>
            <p class="text-sm">{{ getEnding(executedExercises) }} выполнено</p>
          </div>

          <p class="text-md text-center"><span v-if="isWorkoutDone() && totalExercises > 1">Твой рейтинг повышен!</span> Ты молодец! Не забудь
            повторить упражнения завтра!</p>

          <p class="btn home-button mt-3" @click="goMainHandler">Вернуться на главную</p>
        </div>

        <ShareButton :message="prepareShareMessage()" :attachments="prepareShareAttachment()" />

        <Notification class="mt-3"/>
      </div>
      <div v-else>
        <div class="wrapper">
          <h3 class="font-medium text-2xl mb-3 text-center">Тренировка не удалась</h3>
          <p class="text-md text-center">Сейчас не получилось, но не опускай руки и попробуй чуть позже!</p>
          <p class="btn home-button mt-3" @click="goMainHandler">Вернуться на главную</p>
        </div>

        <Notification class="mt-3"/>
      </div>
    </div>

  </div>
</template>

<script>
import bridge from "@vkontakte/vk-bridge";
import {store} from "@/store";
import config from "@/config";
import {decrementHistoryDepth, getNumEnding, setPanelHeader} from "@/lib/utils";
import confetti from "canvas-confetti";

import {finishedWorkout, getStatistic} from "@/services/rating";
import Notification from "@/components/Notification/Notification";
import ShareButton from "@/components/ShareButton/ShareButton";

export default {
  name: "Finish",
  components: {ShareButton, Notification},
  data() {
    return {
      executedExercises: 0,
      totalExercises: 0,

      user: {},

      isLoading: false,
    }
  },
  mounted() {
    decrementHistoryDepth();
    setPanelHeader("", false);

    if (config["dev_mode"] === true) {
      this.loadTotalDataTesting();
    } else {
      this.loadTotalData();
    }
  },
  methods: {
    startFirework() {
      setTimeout(() => {
        let count = 200;
        let defaults = {
          origin: {y: 0.7}
        };

        function fire(particleRatio, opts) {
          confetti(Object.assign({}, defaults, opts, {
            particleCount: Math.floor(count * particleRatio)
          }));
        }

        fire(0.25, {
          spread: 26,
          startVelocity: 55,
        });
        fire(0.2, {
          spread: 60,
        });
        fire(0.35, {
          spread: 100,
          decay: 0.91,
          scalar: 0.8
        });
        fire(0.1, {
          spread: 120,
          startVelocity: 25,
          decay: 0.92,
          scalar: 1.2
        });
        fire(0.1, {
          spread: 120,
          startVelocity: 45,
        });
      }, 500);
    },

    loadResultWorkout() {
      this.totalExercises = store.state.workout.currentWorkout.progress.length;
      const progress = store.state.workout.currentWorkout.progress;

      for (let key in progress) {
        if (!progress[key]) {
          this.executedExercises++;
        }
      }
    },

    isWorkoutDone() {
      const completeLimit = 70;
      let percentOfDone = this.executedExercises / this.totalExercises * 100;

      return percentOfDone > completeLimit;
    },

    goMainHandler() {
      this.$router.replace({path: `/`});
    },

    clearStore() {
      this.executedExercises = 0;
      this.totalExercises = 0;

      store.commit('workout/setCurrentProgressClear');
    },

    getEnding(number) {
      return getNumEnding(number, ['упражнение', 'упражнения', 'упражнений']);
    },

    prepareShareMessage() {
      let msg = `Я ${this.user.sex === 1 ? 'прошла' : 'прошёл'} ещё одну тренировку для здоровой спины и ${this.user.sex === 1 ? 'выполнила' : 'выполнил'} ${this.executedExercises} ${this.getEnding(this.executedExercises)}! \n\n`;
      msg += `Тренируйся вместе со мной: vk.com/app${config.app_id}`;

      return msg;
    },

    prepareShareAttachment() {
      return "photo-208725217_457239022";
    },

    loadTotalData() {
      bridge.send('VKWebAppGetUserInfo').then(res => {
        this.user = res;

        this.loadResultWorkout();

        if (this.executedExercises > 0) {
          this.startFirework();
          // Выполнено хотя бы более N% тренировки
          if (this.isWorkoutDone()) {
            if (this.totalExercises > 1) {
              // очищаем данные об uuid текущей тренировки
              finishedWorkout(store.state.workout.currentWorkout.uuid).then(() => {
                // Обновление локальной статистики
                getStatistic().then(resRating => {
                  console.log("getStatistic", resRating);
                  if (resRating.data.response.item !== null) {
                    // Сохранение рейтинга пользователя в сторе
                    store.commit("rating/setUserRating", resRating.data.response.item);
                  }
                }).catch(err => {
                  console.log(err);
                });
              });

              console.log("after train:", store.state.rating.userRating);
            }
          }
        }
      }).catch(err => {
        console.log('VKWebAppGetUserInfo', err);
      });
    },

    loadTotalDataTesting() {
      this.user = store.state.user.userData;

      this.loadResultWorkout();

      if (this.executedExercises > 0) {
        this.startFirework();
        // Выполнено хотя бы более N% тренировки
        if (this.isWorkoutDone()) {
          if (this.totalExercises > 1) {
            // очищаем данные об uuid текущей тренировки
            finishedWorkout(store.state.workout.currentWorkout.uuid).then(() => {
              getStatistic().then(resRating => {
                console.log("getStatistic", resRating);
                if (resRating.data.response.item !== null) {
                  // Сохранение рейтинга пользователя в сторе
                  store.commit("rating/setUserRating", resRating.data.response.item);
                }
              }).catch(err => {
                console.log(err);
              });
            });
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  @apply p-4 mt-3 rounded-md;
  background-color: var(--white-wrapper-background);
  color: var(--white-wrapper-color);
}
.btn {
  @apply block text-center cursor-pointer rounded-md px-6 py-2 text-gray-700 font-medium text-sm mt-3 shadow-md outline-none;
}

.home-button {
  color: var(--vk_text_blue);
  background-color: var(--vk_secondary_button);
}


</style>