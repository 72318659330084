import bridge from "@vkontakte/vk-bridge";
import {store} from "../store";

export function isMobile() {
    return true;
    // const platform = store.state.launchParams["vk_platform"];
    // if (!platform) {
    //     return false;
    // }
    //
    // return platform.indexOf("mobile") > -1;
}

export function isIPhone() {
    if (store.state.launchParams["vk_platform"] === undefined) {
        return false;
    }

    const platform = store.state.launchParams["vk_platform"];
    const navigators = [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod"
    ];

    if (platform === "mobile_iphone") {
        return true;
    }

    return (
        navigators.includes(navigator.platform) ||
        (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
}

export function isMVk(platform) {
    if (!platform) {
        return false;
    }

    return platform === "mobile_web";
}

export function getNumEnding(iNumber, aEndings) {
    let sEnding, i;
    iNumber = iNumber % 100;
    if (iNumber >= 11 && iNumber <= 19) {
        sEnding = aEndings[2];
    } else {
        i = iNumber % 10;
        switch (i) {
            case 1:
                sEnding = aEndings[0];
                break;
            case 2:
            case 3:
            case 4:
                sEnding = aEndings[1];
                break;
            default:
                sEnding = aEndings[2];
        }
    }
    return sEnding;
}

export function numberWithSpace(x) {
    let parts = x
        .toString()
        .replace(".", ",")
        .split(",");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(",");
}

export function isNumber(event) {
    event = (event) ? event : window.event;
    let charCode = (event.which) ? event.which : event.keyCode;

    if (charCode < 48 || charCode > 57) {
        event.preventDefault();
    } else {
        return true;
    }
}

export function strippedString(str, count) {
    if (str.length > count) {
        return str.slice(0, count - 1) + "...";
    }
    return str;
}

export function prepareLaunchParams(store) {
    let rawLaunchParams = window.location.search.replace("?", "").split("&");
    let launchParams = {};

    rawLaunchParams.forEach(function (item) {
        if (item === "") {
            return;
        }

        let param = item.split("=");
        launchParams[param[0]] = param[1];
    });

    store.commit("setLaunchParams", launchParams);
    store.commit("setLaunchURL", window.location.href);
}

export function setPanelHeader(text, arrowFlag) {
    store.commit("setPanelHeaderArrowFlag", arrowFlag);
    store.commit("setPanelHeaderText", text);
}

export function setActionBar(style = "dark", color = "#f9fafc") {
    bridge
        .send("VKWebAppSetViewSettings", {
            status_bar_style: style,
            action_bar_color: color
        })
        .catch(() => {
        });
}

export function heightIFrame() {
    let section = document.querySelector("#main_container");
    if (!section) {
        return;
    }

    let height = Math.max(section.scrollHeight, section.offsetHeight,
        section.clientHeight, section.scrollHeight, section.offsetHeight);

    return bridge.send("VKWebAppResizeWindow", {
        height: height + 70,
        width: 630
    });
}

export function setTheme() {
    bridge.subscribe(({detail: {type, data}}) => {
        if (type === "VKWebAppUpdateConfig") {
            const schemeAttribute = document.createAttribute("scheme");
            const scheme = data.scheme;
            schemeAttribute.value = scheme ? scheme : "client_light";
            document.body.attributes.setNamedItem(schemeAttribute);

            switch (scheme) {
                case "bright_light": {
                    setActionBar("dark", "#f9fafc");
                    break;
                }

                case "space_gray": {
                    setActionBar("light", "#232324");
                    break;
                }

                default: {
                    setActionBar("light", "#232324");
                }
            }
        }
    });
}

export function dateToString(rawDate) {
    const date = new Date(rawDate);
    return date.toLocaleString("ru", {
        year: "numeric",
        month: "long",
        day: "numeric",
    });
}

export function datetimeToString(rawDate) {
    const date = new Date(rawDate);
    return date.toLocaleString("ru", {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
    });
}

export function decrementHistoryDepth() {
    store.commit("incrementHistoryDepth");
}

export function toTopPage() {
    window.scrollTo(0, 0);
}