import Vue from 'vue'
import Vuex from 'vuex'

import userStore from "@/store/user";
import exerciseStore from "@/store/exercise";
import workoutStore from "@/store/workout";
import ratingStore from "@/store/rating";

Vue.use(Vuex)

export const store = new Vuex.Store({
    modules: {
        user: userStore,
        exercise: exerciseStore,
        workout: workoutStore,
        rating: ratingStore,
    },
    state: {
        isOffline: false,

        launchParams: [],
        launchURL: '',
        device: '',

        panelHeader: {
            isBackArrowNeed: false,
            text: ""
        },

        history: {
            depth: 0,
        }
    },
    mutations: {
        setOffline(state, value) {
            state.isOffline = value;
        },
        setLaunchParams(state, params) {
            state.launchParams = params;
        },
        setLaunchURL(state, params) {
            state.launchURL = params;
        },
        setPanelHeaderArrowFlag(state, value) {
            state.panelHeader.isBackArrowNeed = value
        },
        setPanelHeaderText(state, value) {
            state.panelHeader.text = value
        },
        incrementHistoryDepth(state) {
            state.history.depth = state.history.depth + 1;
        },
        decrementHistoryDepth(state) {
            state.history.depth = state.history.depth - 1;
        }
    }
})
