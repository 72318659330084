<template>
  <div>
    <Header class="mb-5" />
    <Statistic class="mb-5" />
    <Workouts class="mt-5" />
    <Notification class="mt-5" />
    <ExerciseList class="mt-5" />
    <Rating class="mt-5" />
    <Copyright class="mt-5" />
  </div>
</template>

<script>
import {decrementHistoryDepth, setPanelHeader} from "@/lib/utils";

import Header from "../components/Home/Header";
import Rating from "../components/Home/Rating";
import Workouts from "../components/Home/Workouts";
import Notification from "../components/Notification/Notification";
import Copyright from "@/components/Home/Copyright";
import ExerciseList from "@/components/Home/ExercisesList";
import Statistic from "@/components/Home/Statistic";

export default {
  name: "Home",
  components: {Statistic, ExerciseList, Copyright, Notification, Workouts, Rating, Header},
  mounted() {
    decrementHistoryDepth();
    setPanelHeader("Здоровая спина", false);
  },
};
</script>

<style scoped></style>
