<template>
  <div class="wrapper" @click="clickExercisesHandler">
    <div class="flex items-center justify-between">
      <div class="flex-col text-white pr-2">
        <p class="text-md leading-5">Выбирай упражнения<br /> по душе</p>
        <p class="mt-2 text-xs">Полный список всех упражнений</p>
      </div>
    </div>

    <button class="rounded-md bg-white text-gray-700 text-xs py-1 px-2 mt-5 shadow-md">
      Смотреть упражнения
    </button>
  </div>
</template>

<script>
export default {
  name: "ExerciseList",
  methods: {
    clickExercisesHandler() {
      this.$router.push({path: `/exercises`});
    },
  },
}
</script>

<style lang="scss" scoped>
  .wrapper {
    @apply p-4 rounded-md;
    background-color: var(--vk_palette_blue_bright);
  }

  body[scheme="space_gray"] {
  }
</style>