export default {
    namespaced: true,
    state: {
        users: {},
        userRating: {},
    },
    mutations: {
        setUser(state, value) {
            state.users = value;
        },

        setUserRating(state, value) {
            state.userRating = value;
        },
    }
};
