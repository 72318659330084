<template>
  <div>
    <div class="w-full" v-if="! showingAdvertising">
      <div class="wrapper">
        <div v-if="!isLoading">
          <ul>
            <li class="item-exercise" v-for="ex in exercises" :key="ex.id" @click="showExercise(ex)">
              <img :src="'/img/exercise/' + ex.slug + '.jpg'" class="rounded-md mt-1 mr-5 h-16 w-16 border-none"/>
              <div class="overflow-hidden">
                <p class="text-md font-medium">{{ ex.title }}</p>
                <p class="text-xs">{{ ex.description }}</p>
              </div>
            </li>
          </ul>
        </div>

        <div class="text-center" v-if="!isLoading && exercises.length === 0">
          <p class="m-0 text-sm text-gray-800">Список упражнений пуст.</p>
        </div>

        <spinner v-if="isLoading"/>
      </div>
    </div>

    <!-- Плашка с рекламой -->
    <advertising @endShowingAdvertising="showingAdvertising = false"/>
  </div>
</template>

<script>
import {getAllExercises} from "@/services/exercise";
import {store} from "@/store";
import {decrementHistoryDepth, heightIFrame, setPanelHeader} from "@/lib/utils";

import Spinner from "@/components/Spinner/Spinner";
import Advertising from "../components/Advertising/Advertising";

export default {
  name: "ExerciseList",
  components: {Advertising, Spinner},
  data() {
    return {
      exercises: [],

      isLoading: false,

      showingAdvertising: true,
    }
  },
  mounted() {
    decrementHistoryDepth();
    setPanelHeader("Список", true);
    heightIFrame();

    this.loadAllExercises();
  },
  methods: {
    loadAllExercises() {
      if (store.state.exercise.exercises.length === 0) {
        this.isLoading = true;

        getAllExercises().then(res => {
          store.commit("exercise/setExercises", res.data.response.items);
          this.exercises = res.data.response.items;
        }).catch(err => {
          console.log("ошибка загрузки списка упражнений", err);
        }).finally(() => {
          this.isLoading = false;
        });
      }

      this.exercises = store.state.exercise.exercises;
    },
    showExercise(exercise) {
      // Очистка данных о текущей тренировке
      store.commit("workout/setCurrentProgressClear");

      // Сохранение упражнений в сторе
      store.commit("workout/setExercisesByWorkout", {
        type: "single-exercise",
        exercises: [
          exercise
        ],
      });

      this.$router.push({path: `/workout/exercise/single-exercise`});
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  @apply p-4 mt-3 rounded-md;
  background-color: var(--white-wrapper-background);
  color: var(--white-wrapper-color);
}

.item-exercise {
  @apply rounded-md py-2 px-4 cursor-pointer list-none flex;

  background-color: var(--white-wrapper-background);
  color: var(--white-wrapper-color);
}

.item-exercise:not(:first-child) {
  @apply mt-2;
}
</style>