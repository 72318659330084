<template>
  <svg :style="{ color: color }" :class="[ classes ]" version="1.1" viewBox="0 0 28 28" width="28px"
       xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g fill="none" fill-rule="evenodd" id="Page-1" stroke="none" stroke-width="1">
      <g id="done_outline_28">
        <rect height="28" width="28" x="0" y="0"></rect>
        <path d="M11,18.5857864 L5.70710678,13.2928932 C5.31658249,12.9023689 4.68341751,12.9023689 4.29289322,13.2928932 C3.90236893,13.6834175 3.90236893,14.3165825 4.29289322,14.7071068 L10.2928932,20.7071068 C10.6834175,21.0976311 11.3165825,21.0976311 11.7071068,20.7071068 L23.7071068,8.70710678 C24.0976311,8.31658249 24.0976311,7.68341751 23.7071068,7.29289322 C23.3165825,6.90236893 22.6834175,6.90236893 22.2928932,7.29289322 L11,18.5857864 Z"
              fill="currentColor" fill-rule="nonzero" id="↳-Icon-Color"></path>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "Done",
  props: {
    color: {
      type: String
    },
    classes: {
      type: String
    }
  }
}
</script>

<style scoped>

</style>