import VueRouter from 'vue-router'

import Home from "./panels/Home.vue";
import Copyright from "@/panels/Copyright";
import Welcome from "@/panels/workout/Welcome";
import Finish from "@/panels/workout/Finish";
import ExerciseList from "@/panels/ExerciseList";
import Rating from "@/panels/Rating";
import Workout from "./panels/workout/Workout";

export const router = new VueRouter({
    mode: "hash",
    routes: [
        {path: '/', component: Home},

        // Тренировки
        {path: `/workout/welcome/:id`, component: Welcome}, // Знакомство с тренировкой
        {path: `/workout/exercise/:id`, component: Workout}, // Сама тренировка
        {path: `/workout/finish`, component: Finish}, // Окончание тренировки

        // Упражнения
        {path: `/exercises`, component: ExerciseList},

        // Рейтинг
        {path: `/rating`, component: Rating},

        {path: `/copyright`, component: Copyright},
    ],
});
