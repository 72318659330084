<template>
  <section id="main_container">
    <!-- Интернет есть -->
    <div v-if="! isOffline">
      <panel-header>
        <span slot="arrow" v-if="panelHeader.isBackArrowNeed === true"><panel-header-back-arrow/></span>
        <span slot="text">{{ panelHeader.text }}</span>
      </panel-header>
      <div :class="{ 'iOS-container-safe-bottom': isIPhone, 'container-margin-top': isMobile }" class="container mx-auto">
        <router-view></router-view>
      </div>
    </div>

    <!-- Интернет отсутствует -->
    <div v-if="isOffline">
      <panel-header>
        <span slot="text">Ошибка</span>
      </panel-header>
      <div :class="{ 'iOS-container-safe-bottom': isIPhone, 'container-margin-top': isMobile }" class="container mx-auto">
        <p class="text-sm theme-text-color text-center">Что-то пошло не так. Возможно, отсутствует интернет.<br/> Попробуйте позже.</p>
        <p class="text-center mt-5"><span class="error-btn" @click="checkOffline">Обновить</span></p>
      </div>
    </div>
  </section>
</template>

<script>
import "./assets/css/app.scss";

import bridge from "@vkontakte/vk-bridge";
import {store} from "./store";
import {heightIFrame, isIPhone, isMobile, prepareLaunchParams, setTheme} from "./lib/utils";
import {getAllExercises} from "@/services/exercise";
// import config from "@/config";

import PanelHeaderBackArrow from "./components/PanelHeader/PanelHeaderBackArrow";
import PanelHeader from "./components/PanelHeader/PanelHeader";


export default {
  name: "App",
  components: {PanelHeader, PanelHeaderBackArrow},
  data() {
    return {
      panelHeader: {
        isBackArrowNeed: false,
        text: ""
      }
    }
  },
  mounted() {
    this.initBridge();
    this.getUser();
    this.onlineStatusListener();
    this.setPanelHeader();
    this.loadAllExercises();

    setTheme();
  },
  created() {
    prepareLaunchParams(store);
  },
  updated() {
    heightIFrame();
    this.setPanelHeader();
  },
  methods: {
    setPanelHeader() {
      this.panelHeader.isBackArrowNeed = store.state.panelHeader.isBackArrowNeed;
      this.panelHeader.text = store.state.panelHeader.text;
    },
    initBridge() {
      bridge.send("VKWebAppInit", {}).catch(err => {
        console.log("VKWebAppInit", err);
      });
    },
    onlineStatusListener() {
      window.addEventListener('online', () => {
        store.commit("setOffline", false);
      });

      window.addEventListener('offline', () => {
        store.commit("setOffline", true);
      });
    },
    checkOffline() {
      if (navigator.onLine === true) {
        store.commit("setOffline", false);
      } else {
        store.commit("setOffline", true);
      }
    },
    getUser() {
      // if (config["dev_mode"] === true) {
      //   store.state.launchParams["vk_user_id"] = "8519200";
      // }

      bridge.send('VKWebAppGetUserInfo').then(res => {
        console.log("VKWebAppGetUserInfo", res);
        store.commit("user/setUser", res);
      }).catch(err => {
        console.log('err user', err.error_data.error_code, err.error_data.error_description, err.error_data.error_msg, err.error_data.error_reason);
      });
    },
    async loadAllExercises() {
      getAllExercises().then(res => {
        store.commit("exercise/setExercises", res.data.response.items);
      }).catch(err => {
        console.log("ошибка загрузки списка упражнений", err);
      });
    }
  },
  computed: {
    isIPhone() {
      return isIPhone();
    },

    isMobile() {
      return isMobile();
    },

    isOffline() {
      return store.state.isOffline;
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "assets/css/text";
</style>
