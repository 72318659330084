<template>
  <div class="wrapper">
    <h3 class="text-md font-medium mb-3">Тренировки</h3>

    <div class="workout-wrapper workout-easy" @click="clickWorkoutHandler(workoutEasy)">
      <div class="flex flex-col text-white pr-2">
        <p class="font-medium">Щадящая</p>
        <p class="text-xs">Щадящие упражнения, чтобы снять болевой синдром</p>
      </div>

      <img src="/img/workout/covers/easy.png" class="w-12" />
    </div>

    <div class="workout-wrapper workout-normal" @click="clickWorkoutHandler(workoutNormal)">
      <div class="flex flex-col text-white pr-2">
        <p class="font-medium">Умеренная</p>
        <p class="text-xs">Восстановление тонуса мышц спины и плеч</p>
      </div>

      <img src="/img/workout/covers/normal.png" class="w-12"/>
    </div>

    <div class="workout-wrapper workout-hard" @click="clickWorkoutHandler(workoutHard)">
      <div class="flex flex-col text-white pr-2">
        <p class="font-medium">Полноценная</p>
        <p class="text-xs">Тренировка для предотвращения болей в пояснице</p>
      </div>

      <img src="/img/workout/covers/hard.png" class="w-12"/>
    </div>
  </div>
</template>

<script>
import {workoutEasy, workoutHard, workoutNormal} from "@/services/workout";

export default {
  name: "Workouts",
  data() {
    return {
      workoutEasy: "",
      workoutNormal: "",
      workoutHard: "",
    }
  },
  mounted() {
    this.workoutEasy = workoutEasy;
    this.workoutNormal = workoutNormal;
    this.workoutHard = workoutHard;
  },
  methods: {
    clickWorkoutHandler(typeWorkout) {
      this.$router.push({path: `/workout/welcome/${typeWorkout}`});
    },
  },
}
</script>

<style lang="scss" scoped>
  @import "../../assets/css/vk/palette.css";

  .wrapper {
    @apply mt-3 rounded-md p-4;
    background-color: var(--white-wrapper-background);
    color: var(--white-wrapper-color);
  }

  .workout-wrapper {
    @apply cursor-pointer rounded-md px-4 py-2 flex items-center justify-between overflow-hidden;
    min-height: 5rem;
    max-height: 6rem;
  }

  .workout-wrapper:not(:first-child) {
    @apply mt-2;
  }

  .workout-easy {
    background-color: var(--vk_palette_blue_bright)
  }

  .workout-normal {
    background-color: var(--vk_palette_green)
  }

  .workout-hard {
    background-color: var(--vk_palette_orange)
  }
</style>