<template>
  <div class="header rounded-md">
    <div class="inner-header flex flex-col">
      <img :src="Object.keys(user).length > 0 ? user.photo_200 : '/img/avatar/no_avatar.png'"
           class="block rounded-full w-20 h-20 border-4 border-white mb-3"/>

      <p class="text-xl font-medium overflow-hidden" v-html="getUserName()"></p>
      <p class="text-xs px-2">
        Занимайся каждый день и становись лучше! Хорошей тренировки!
        <img src="/img/emoji/muscle.png" class="inline h-4" />
      </p>
    </div>
  </div>
</template>

<script>
import bridge from "@vkontakte/vk-bridge";
import {store} from "../../store";

export default {
  name: "Header",
  data() {
    return {
      user: {},
      isLoading: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.loadUser();
    });
  },
  methods: {
    getUserName() {
      let firstName = "";
      let lastName = "";

      if (this.user !== undefined) {
        if (this.user.first_name !== undefined) {
          firstName = this.user.first_name.trim();
        }

        if (this.user.last_name !== undefined) {
          lastName = this.user.last_name.trim();
        }
      }

      return firstName + " " + lastName;
    },

    loadUser() {
      // Проверяем пользователя в сторе
      const storedUser = store.state.user.userData;
      if (Object.keys(storedUser).length > 0) {
        this.user = storedUser;
        return;
      }

      // Если пользователя в сторе нет, то делаем запрос в bridge
      this.isLoading = true;
      bridge.send('VKWebAppGetUserInfo').then(res => {
        this.user = res;

        // Сохранение данных о пользователе в кеше
        store.commit("user/setUser", res);
      }).catch(err => {
        console.log('err user', err.error_data.error_code, err.error_data.error_description, err.error_data.error_msg, err.error_data.error_reason);
      }).finally(() => {
        this.isLoading = false;
      });
    }
  }
}
</script>

<style lang="scss" scoped>

.header {
  position: relative;
  text-align: center;
  background: linear-gradient(60deg, rgba(84, 58, 183, 1) 0%, rgba(0, 172, 193, 1) 100%);
  color: white;
}

.inner-header {
  height: 200px;
  width: 100%;
  margin: 0;
  padding: 0;
}

.flex { /*Flexbox for containers*/
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
</style>