export default {
    namespaced: true,
    state: {
        exercisesByWorkout: {}, // содержит список упражений по названию тренировки

        currentWorkout: {
            currentExercise: 0,
            progress: [],
            uuid: "",
        }
    },
    mutations: {
        setExercisesByWorkout(state, payload) {
            state.exercisesByWorkout[payload.type] = payload.exercises;
        },

        setCurrentProgressClear(state) {
            state.currentWorkout.currentExercise = 0;
            state.currentWorkout.progress = [];
        },

        setWorkoutCurrentExercise(state, id) {
            state.currentWorkout.currentExercise = id;
        },

        setWorkoutProgress(state, payload) {
            state.currentWorkout.progress[payload.id] = payload.pass;
        },

        setWorkoutCurrentUUID(state, uuid) {
            state.currentWorkout.uuid = uuid;
        },
    }
};
