export default {
    namespaced: true,
    state: {
        userData: {}
    },
    mutations: {
        setUser(state, value) {
            state.userData = value;
        },
    }
};
