<template>
    <div class="text-center">
        <span class="loading"></span>
    </div>
</template>

<script>
    export default {
        name: "Spinner",
    }
</script>

<style lang="scss" scoped>
    .loading {
        display: inline-block;
        width: 25px;
        height: 25px;
        border: 3px solid rgba(0, 0, 0, .3);
        border-radius: 50%;
        border-top-color: #fff;
        animation: spin 1s ease-in-out infinite;
        -webkit-animation: spin 1s ease-in-out infinite;
        text-align:center;
        margin-top: 5px;
    }

    @keyframes spin {
        to {
            -webkit-transform: rotate(360deg);
        }
    }

    @-webkit-keyframes spin {
        to {
            -webkit-transform: rotate(360deg);
        }
    }
</style>