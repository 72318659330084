import {store} from "@/store";
import axios from "axios";

export const subscribeNotification = () => {
    const endpoint = "/api/notification/subscribe";
    let params = {
        'user_vk_id': parseInt(store.state.launchParams["vk_user_id"]),
        'url': store.state.launchURL
    };

    return axios.post(endpoint, params);
}

export const unsubscribeNotification = () => {
    const endpoint = "/api/notification/unsubscribe";
    let params = {
        'user_vk_id': parseInt(store.state.launchParams["vk_user_id"]),
        'url': store.state.launchURL
    };

    return axios.post(endpoint, params);
}

export const checkSubscription = () => {
    const endpoint = "/api/notification/check";
    let params = {
        'user_vk_id': parseInt(store.state.launchParams["vk_user_id"]),
        'url': store.state.launchURL
    };

    return axios.post(endpoint, params);
}