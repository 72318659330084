<template>
  <div class="rounded-md">
    <div v-if="!isLoading">
      <h3 class="title">Самые усердные</h3>

      <ul>
        <li class="item-rating" v-for="user in users" :key="user.id">
          <img :src="user.user_avatar ? user.user_avatar : '/img/avatar/no_avatar.png'"
               class="rounded-full mr-5 h-12 w-12 border-none"/>
          <div class="overflow-hidden">
            <p class="text-sm font-medium">{{ user.first_name }} {{ user.last_name }}</p>
            <p class="text-xs">{{ user.finished_workouts }} {{ getExerciseEnding(user.finished_workouts) }}</p>
          </div>
        </li>
      </ul>

      <div class="button" @click="clickRatingHandler">
        <div class="flex items-center">
          <div class="w-10 flex justify-center mr-3">
            <Users3Outline color="#5c9ce6"/>
          </div>
          <div class="w-full flex text-xs font-medium">Смотреть рейтинг</div>
        </div>
      </div>
    </div>

    <spinner v-else/>
  </div>
</template>

<script>
import {store} from "../../store";
import {getRatingTop} from "@/services/rating";
import {getNumEnding} from "@/lib/utils";

import Users3Outline from "@/components/Svg/24/Users3Outline";
import Spinner from "@/components/Spinner/Spinner";

export default {
  name: "Rating",
  components: {Spinner, Users3Outline},
  data() {
    return {
      users: [],
      isLoading: false,
    }
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    getUsers() {
      // Проверяем наличие в сторе
      const storedUsers = store.state.rating.users;
      if (Object.keys(storedUsers).length > 0) {
        this.users = storedUsers;
        return;
      }

      this.isLoading = true;
      getRatingTop(3).then(res => {
        if (res.data.response.items !== null) {
          this.users = res.data.response.items;

          // Сохранение пользователей из рейтинга в сторе
          store.commit("rating/setUser", this.users);
        }
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.isLoading = false;
      });
    },

    clickRatingHandler() {
      this.$router.push({path: `/rating`});
    },

    getExerciseEnding(number) {
      return getNumEnding(number, ['тренировка', 'тренировки', 'тренировок']);
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  @apply mt-3 rounded-md flex items-center justify-between;
  background-color: var(--vk_palette_blue_bright);
}

.title {
  @apply font-medium mb-3;
  color: var(--white-wrapper-color);
}

.button {
  @apply px-6 py-2 rounded-md cursor-pointer text-sm font-medium w-full
  outline-none h-12 flex items-center mt-3;

  background-color: var(--white-wrapper-background);
  color: var(--default-text-color);
}

.item-rating {
  @apply rounded-md py-2 px-4 list-none flex items-center overflow-hidden;

  background-color: var(--white-wrapper-background);
  color: var(--default-text-color);
}

.item-rating:not(:first-child) {
  @apply mt-2;
}

</style>