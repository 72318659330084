import {store} from "@/store";
import axios from "axios";

export const workoutEasy = "easy";
export const workoutNormal = "normal";
export const workoutHard = "hard";

// Возвращает список всех доступных тренировок
export const getAllWorkouts = () => {
    const endpoint = "/api/workout/get-all";
    let params = {
        'user_vk_id': parseInt(store.state.launchParams["vk_user_id"]),
        'url': store.state.launchURL
    };

    return axios.post(endpoint, params);
}

// Возвращает данные о тренировке по её slug
export const getWorkoutBySlug = (slug) => {
    const endpoint = "/api/workout/get-by-slug";
    let params = {
        'user_vk_id': parseInt(store.state.launchParams["vk_user_id"]),
        'url': store.state.launchURL,
        'slug': slug,
    };

    return axios.post(endpoint, params);
}