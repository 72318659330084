<template>
  <svg
      :style="{ color: color }" :class="[ classes ]"
      height="24" width="24" xmlns="http://www.w3.org/2000/svg"
      style="display: inherit; margin-top: -2px"
  >
      <path
          d="m11.9964558 3.72539827c-1.1157517.07873001-1.9964558 1.00881614-1.9964558 2.14460173l-.001 2.11707307-.02042601.00489936c-4.53228854.93898423-7.87857399 4.95513877-7.87857399 9.69345567 0 .3442757.01760694.6873469.05243309 1.0276162.08302319.8111789 1.11566534 1.1026651 1.6105859.4546237l.18680613-.2364796c1.45931688-1.7864465 3.53701534-2.9583156 5.83568452-3.265329l.21349036-.0257863.001 2.4943341c0 .480596.1611979.9475731.4578298 1.3260345.7324928.9345598 2.083906 1.0983675 3.0184658.3658747l7.8236794-6.1320731c.1362424-.1067846.2590901-.2296322.3658747-.3658747.7324928-.9345598.5686851-2.2859729-.3658747-3.0184657l-7.8236794-6.13207312c-.3784614-.29663189-.8454386-.45782978-1.3262956-.45782978zm.1535442 1.79460173c.0782791 0 .1542986.02624152.2159086.07453043l7.8236794 6.13207307c.1521377.119243.178804.3392405.059561.4913782-.0173835.022179-.037382.0421774-.059561.0595609l-7.8236794 6.1320731c-.1521377.119243-.3721352.0925767-.4913782-.059561-.0482889-.06161-.0745304-.1376295-.0745304-.2159086l-.001-3.4473341c-.0001459-.5027411-.4121197-.9078807-.9147929-.8996173l-.2331477.0038326-.3422191.0165251c-2.27409519.1451443-4.39673811.9871577-6.11854354 2.3658366l-.17429676.1436841.02379907-.1353588c.64281759-3.4154489 3.43492738-6.1053589 6.96835753-6.53625978.4514826-.05505817.7909275-.43830709.7910523-.89313443l.0007911-2.88207308c0-.19354667.1567003-.35024701.35-.35024701z"
          fill="currentColor"
          fill-rule="nonzero"
      />
  </svg>
</template>

<script>
export default {
  name: "Share",
  props: {
    color: {
      type: String
    },
    classes: {
      type: String
    }
  }
}
</script>

<style scoped>

</style>