<template>
  <div>
    <div v-if="! isLoading">
      <div class="wrapper mb-3" v-if="exercises.length > 1">
        <p class="text-sm font-medium mb-2">Прогресс тренировки</p>
        <div class="flex justify-between flex-row">
          <div class="rounded-md flex-1 h-2 item-exercise bg-gray-100"
               :class="{
                'item-exercise-active': i === exerciseId,
                'item-exercise-success': isCurrentExercisePass(i) === false,
                'item-exercise-fail': isCurrentExercisePass(i) === true,
             }"
               v-for="(ex, i) in exercises" :key="ex.id"></div>
        </div>
      </div>
      <div class="wrapper">
        <div class="h-3 relative max-w-xl rounded-full overflow-hidden">
          <div class="w-full h-full bg-gray-200 absolute"></div>
          <div ref="bar" class="transition-all ease-out duration-1000 h-full bg-green-500 relative w-0"></div>
        </div>

        <!--  Изображение упражнения  -->
        <div class="h-48 my-3">
          <img :src="'/img/exercise/' + currentExercise.slug + '.jpg'" class="rounded-md mx-auto h-48"
               @load="loadImage" v-show="exerciseImage.isLoaded"/>

          <div class="flex h-48 justify-center items-center" v-if="exerciseImage.isLoading">
            <spinner />
          </div>
        </div>

        <!--  Описание тренировки  -->
        <p class="text-sm">{{ currentExercise.description }}</p>

        <!--  Тренировка запущена  -->
        <div v-if="(! isExerciseRunning || isExercisePaused) && !isExerciseDone()">
          <!--  Кнопка начала тренировки  -->
          <span
              class="button start-button"
              @click="startExercise">
          <span v-if="! isExercisePaused">Начать</span>
          <span v-else>Продолжить</span>
        </span>
        </div>

        <!--  Тренировка не запущена/остановлена  -->
        <div v-if="isExerciseRunning && ! isExercisePaused" class="mt-3">
          <!--  Пауза выполнения упражнения  -->
          <div class="mt-3 text-center">
          <span
              class="button pause-button"
              @click="pauseExercise">
            Приостановить
          </span>
          </div>
        </div>

        <!--  Пропуск упражнения  -->
        <div class="text-xs mt-4 text-center">
          <span class="skip-button" @click="nextExercise(true)">Пропустить упражнение</span>
        </div>
      </div>
    </div>
    <div class="wrapper" v-else>
      <spinner/>
    </div>
  </div>
</template>

<script>
import {store} from "@/store";
import bridge from "@vkontakte/vk-bridge";
import {decrementHistoryDepth, heightIFrame, setPanelHeader} from "@/lib/utils";
import {getWorkoutBySlug} from "../../services/workout";
import {getExerciseByWorkoutId} from "../../services/exercise";

import Spinner from "../../components/Spinner/Spinner";
import {toTopPage} from "../../lib/utils";

export default {
  name: "Workout",
  components: {Spinner},
  data() {
    return {
      isExerciseRunning: false,
      isExercisePaused: false,

      timer: null,

      progressBar: {
        progress: 0,
        intervalSpeed: 1000,
        incrementStep: 100,
      },

      exerciseImage: {
        src: "",
        isLoaded: false,
        isLoading: false
      },

      exercises: [],
      exerciseId: 0,
      currentExercise: {},

      isLoading: false,
    }
  },
  mounted() {
    decrementHistoryDepth();
    setPanelHeader("Тренировка", true);
    heightIFrame();
    toTopPage();

    this.exercises = store.state.workout.exercisesByWorkout[this.$route.params.id];
    // данные о тренировке не подгрузились, делаем новый запрос
    if (this.exercises.length === 0) {
      this.isLoading = true;
      getWorkoutBySlug(this.$route.params.id).then(res => {
        this.workoutInfo = res.data.response.item;
        store.commit("workout/setWorkoutCurrentUUID", res.data.response.uuid);

        // Загрузка упражнений для полученной тренировке
        getExerciseByWorkoutId(this.workoutInfo.id).then(res => {
          this.exercises = res.data.response.items;

          // Сохранение упражнений в сторе
          store.commit("workout/setExercisesByWorkout", {
            type: this.$route.params.id,
            exercises: this.exercises,
          });
        }).catch(err => {
          throw "Ошибка получения упражнений: " + err;
        });
      }).catch(err => {
        console.log('Err: ', err);
      }).finally(() => {
        this.isLoading = false;
      });
    }

    this.currentExercise = this.exercises[this.exerciseId];
    // this.currentExercise.duration = 3; // TODO для тестов 3 секунды продолжительность упражнений
    this.progressBar.incrementStep = 100 / this.currentExercise.duration;

    this.exerciseImage.src = this.currentExercise.slug;

  },
  methods: {
    startExercise() {
      store.commit("workout/setWorkoutCurrentExercise", this.exerciseId);

      this.isExerciseRunning = true;

      if (!this.isExercisePaused) {
        this.startCountdown();
      }

      this.isExercisePaused = false;
    },

    pauseExercise() {
      this.isExercisePaused = true;
    },

    startCountdown() {
      this.timer = setInterval(() => {
        if (!this.isExercisePaused) {
          this.progressBar.progress += this.progressBar.incrementStep;

          if (this.$refs["bar"] !== undefined) {
            this.$refs["bar"].style.width = this.progressBar.progress + "%";
          }

          if (this.isExerciseDone()) {
            this.nextExercise();
          }
        }
      }, this.progressBar.intervalSpeed);
    },

    isExerciseDone() {
      return this.progressBar.progress >= 100 + this.progressBar.incrementStep;
    },

    clearCountdown() {
      clearInterval(this.timer);
      this.progressBar.progress = 0;
      this.$refs["bar"].style.width = "0%";
    },

    nextExercise(pass = false) {
      this.clearCountdown();
      this.isExerciseRunning = false;
      this.isExercisePaused = false;

      store.commit("workout/setWorkoutProgress", {
        id: this.exerciseId,
        pass: pass,
      });

      if (this.exerciseId === this.exercises.length - 1) {
        this.$router.replace({path: `/workout/finish`});
        return;
      }

      this.exerciseId++;
      this.currentExercise = this.exercises[this.exerciseId];
      // this.currentExercise.duration = 3; // TODO для тестов 3 секунды продолжительность упражнений
      this.progressBar.incrementStep = 100 / this.currentExercise.duration;

      // Проверка загрузки изображения для тренировки
      this.exerciseImage.src = "";
      this.exerciseImage.isLoaded = false;
      this.exerciseImage.isLoading = true;
      this.exerciseImage.src = this.currentExercise.slug;

      bridge.send("VKWebAppTapticNotificationOccurred", {"type": "success"}).catch(err => {
        console.log("VKWebAppTapticNotificationOccurred", err);
      });

      toTopPage();
    },

    isCurrentExercisePass(exerciseId) {
      const progress = store.state.workout.currentWorkout.progress;

      if (progress.length === 0 || exerciseId < 0) {
        return null;
      }

      return progress[exerciseId];
    },

    loadImage() {
      this.exerciseImage.isLoaded = true;
      this.exerciseImage.isLoading = false;
    }
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  @apply p-4 mt-3 rounded-md;
  background-color: var(--white-wrapper-background);
  color: var(--white-wrapper-color);
}

.button {
  @apply block text-center cursor-pointer rounded-md px-6 py-2 text-gray-700 font-medium text-sm mt-3 shadow-md outline-none;
}

.start-button {
  color: white;
  background-color: var(--vk_btn_blue);
}

.pause-button {
  color: var(--vk_text_blue);
  background-color: var(--vk_btn_gray);
}

.item-exercise:not(:last-child) {
  @apply mr-2;
}

.item-exercise-active {
  @apply bg-gray-200;
}

.item-exercise-success {
  @apply bg-green-200;
}

.item-exercise-fail {
  @apply bg-red-200;
}

.skip-button {
  @apply cursor-pointer font-medium;
  color: var(--default-text-color);
}

</style>